// ***********************************************
// Settings
// ***********************************************

// Touch Menu Opener Label anzeigen "Menu"
$show_touch_menu_label: false; // TODO: Obsolete?
// Footer Menu Vertikal oder Horizontal darstellen
$show_footer_menu_vertical: false;
// Dock Form Actions (Save, Delete, etc.)
$dock_form_actions: true;
// Navigation Bar has a search-block inside it? (Headroom.js)
$headroom_searchblock: false;

// ***********************************************
// Color Variables
// Colors used in the sample global.styles. You can use these to recolor many
// elements such as forms, tables, tabs etc.
// ***********************************************

// webks
$webks_grey: #454545;
$webks_grey_light: #E2E2E2;
$webks_blue: #009CFF;

// Colors
$white: #fff;
$black: #000;
$highlight:     #192e4c;    // Primary Company CD color
$highlight_sec: #d51135;    // Secondary Company CD color
$black_glass: rgba(0,0,0,0.85);

$info_color:    #789bd0;
$debug:         rgba(255,255,0,0.5);

// Background Colors
$highlight_bg_color: $highlight;

// Basic Greytone
$grey: #d9d9d9;
$grey_light: lighten($grey, 5%);
$grey_dark: darken($grey, 5%);

// Grey-Brown
$grey_brown: #726d6d;
$grey_brown_dark: #545051;

// Basic Green (for add buttons)
$green: #6B9C00;
$green_light: #8FCF00;
$green_dark: darken($green, 5%);
$brand-success: $green;

// Basic Red (for remove buttons)
$red: #d51135;
$red_light: lighten($red, 15%);
$red_dark: darken($red, 5%);

// Text Colors
$text_color:     #000;
$highlight_text_color: #fff; // Text color on highlight_bg

// (Text) Selection Color
$selection_color: $highlight;
$selection_color_foreground: $highlight_text_color;

// Link Colors
$link_color: $text_color;
$link_color_hover: $highlight; // hover
$link_color_active: $link_color_hover; // active
$link_color_focus: $link_color_hover; // focus
$link_color_visited: $link_color; // visited
$highlight_link_color: $highlight; // Link color on highlight_bg

// Menus
$menu_flyout_background: $highlight;
$menu_flyout_color: $highlight_text_color;
$menu_offcanvas_background: $menu_flyout_background;
$menu_offcanvas_color: $menu_flyout_color;

// Page Element Colors
$page_background: white;
$border:       $grey;    // all borders set in global.styles - tables, fieldset, primary links

// Shadows
$shadow_intensity: rgba(0,0,0,0.2);
$shadow: 0px 4px 3px 0px $shadow_intensity, 0px -3px 3px -3px $shadow_intensity;
$shadow_top: 0px -5px 9px -1px rgba(0,0,0,0.4);

// Forms
$form_item_border: $grey;
$form_item_border_hover: darken($grey, 8%);
$form_item_border_active: darken($grey, 13%);

// ***********************************************
// Message Colors (Warnings etc.)
// ***********************************************
$message_error: #e05e0c; // eg. red
$message_warning: #ffca21; // eg. yellow
$message_status: #409800; // eg. green

// ***********************************************
// Metrics
// ***********************************************
$border_radius: 5px;
$border_radius_small: 3px;

// ***********************************************
// jQuery.UI
// ***********************************************
$jq_image_folder: "images/jquery.ui/";

$jq_border: $grey;
$jq_border_radius: $border_radius_small;
$jq_background: white;
$jq_color: $text_color;

$jq_highlight_background: $highlight;
$jq_highlight_border: $highlight;
$jq_highlight_color: white;

$jq_hover_background: $highlight;
$jq_hover_border: $highlight;
$jq_hover_color: white;

$jq_active_background: $highlight;
$jq_active_border: $highlight;
$jq_active_color: white;

$jq_error_background: $red_light;
$jq_error_border: $red;
$jq_error_color: $red;

$jq_header_background: $grey_light;

// ***********************************************
// Object Configuration
// ***********************************************

// Buttons
$btn_radius: $border_radius;

// ***********************************************
// Layout (and responsiveness)
// ***********************************************
$grid: 10px;
$grid_percentage: 0.87719%;
$thin_grid: 5px;
$grid_vertical: 15px;
$mobile_grid: $grid;
$page_max_width: 1140px;

$sidebar_first_width: 162px + $grid;
$sidebar_second_width: 200px + $grid;

// View Image (Image Column)
$view_image_width: 120px;

// Breakpoints (set same as the theme settings) # OLD DEFINITIONS
$desktop_min_width: $page_max_width;
$desktop_floating_breakpoint: $desktop_min_width - 150; // Laesst floatings um den angegebenen Wert frueher greifen
$tablet_landscape_start: 769px;
$tablet_landscape_end: $page_max_width - 1;
$tablet_portrait_start: 531px;
$tablet_portrait_end: $tablet_landscape_start - 1;
$smartphone_start: 0px;
$smartphone_end: $tablet_portrait_start - 1;


// ***********************************************
// Breakpoints (# new definition)
// ***********************************************
// Here we define the breakpoints which will become the upper border for each media size.

// Ranges (used for MQ)
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// The function em-calc() calculates the em-value from a px-value.
$small-breakpoint:  640px  !default;
$medium-breakpoint: 1024px !default;
$large-breakpoint:  1140px !default;
$xlarge-breakpoint: 1920px !default;

// Here we define the lower and upper bounds for each media size
$small-range:   (0, $small-breakpoint) !default; /* 0, 640px */
$medium-range:  ($small-breakpoint, $medium-breakpoint) !default; /* 641px, 1024px */
$large-range:   ($medium-breakpoint, $large-breakpoint)  !default; /* 1025px, 1440px */
$xlarge-range:  ($large-breakpoint, $xlarge-breakpoint) !default; /* 1441px, 1920px */
$xxlarge-range: ($xlarge-breakpoint, em-calc(99999999)) !default; /* 1921px, ... */

// Media Queries
$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$small-up: $screen !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;


// ***********************************************
// Typography
// These font stacks are an exact copy of the stacks found in AT Cores
// theme settings for web safe fonts.
// ***********************************************

$font_first: 'Lato', Verdana, Geneva, 'DejaVu Sans', Arial, Helvetica, sans-serif;
$font_second: Verdana, Geneva, 'DejaVu Sans', Arial, Helvetica, sans-serif;
$font_code: Consolas, Monaco, 'Courier New', Courier, monospace, sans-serif;

$iconfont: 'iconfont';

$font_size: 100%;  // Basic font size (for Body)
$font_size_px: 16px;
$font_normal: 1rem;
$font_medium:14px;
$font_small: 0.85em;
$font_large: 1.143em;
$font_weight_normal: 300;
$font_weight_bold: 700;

$line_height: 1.571em;
$less_line_height:1.25em;
$small_line_height: 1em; // Line height for $font_small

$paragraph_spacing: 0.65em;

