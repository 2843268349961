/* Holds reusable objects for global use */

// Mixins
// *-mod means: It´s a modifier mixin, wich for eg. only change the color of a button. 

// ***********************************************
// Buttons
// ***********************************************
@mixin btn($color_scheme: 'default', $size: 'default', $icon: null, $icon_only: 'false'){
  border-width:1px;
  border-style:solid;
  display: inline-block;
  font-size: $font_normal;
  font-weight: $font_weight_normal;
  line-height: 1.05em;
  cursor:pointer;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius:$btn_radius;
   
  // Color
  @if($color_scheme != null){
    @include btn-mod($color_scheme);
  }
  
  // Size
  @if($size == xs){
    font-size:$font_small;
    padding:0.15em 15px;
  }@else if( $size == xl){
    font-size: 19px;
    padding: 0.45em 30.72px;
  }@else if( $size == xxl){
    font-size: 24px;
    padding: 0.55em 40.96px;
  }@else if( $size == xxxl){
    font-size: 24px;
    padding: 0.65em 46.96px;
  }@else{
    // Default
    padding:0.55em 10px;
  }

  // Icon Only (remove text, show only the icon)
  @if($icon_only != 'false'){
    text-indent:-9000px;
    position:relative;
    padding:0.55em 15px;
  }
  
  @if($icon_only != 'false' OR $icon != null){
    &::before{
      // Icon
      @if($icon_only != 'false'){
        text-indent:0;
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        line-height:2em;
        vertical-align:middle;
      }else{
        line-height: 1;
      }
      @if($icon != null){
        display: inline-block;
        font-family: $iconfont;
        font-style: normal;
        font-weight: normal;
        margin-right:5px;
        content: map-get($icon_list, $icon);
      }
    }
  }
  &:hover{
    text-decoration:none;
  }
}

// Button Modifier
@mixin btn-mod($color_scheme, $actions_only: 'false'){
  // Adds only the new colors, not the entire button code
  // $actions_only: For buttons they show onother color scheme on :hover & :active
  // TODO: actions_only may not the best solution, maybe we simply have to define seperate color schemes for buttons like eg. 'normal-to-red'
  @if($color_scheme == 'red'){
    @if($actions_only == 'false'){
      background-color:$red !important;
      border-color:$red !important;
      text-shadow:0 1px 1px lighten($red, 12%);
      color:white !important;
    }
    &:hover{
      background-color: lighten($red, 3%) !important;
      border-color: lighten($red, 3%) !important;
      color:white !important;
    }
    &:active,
    &:focus{
      background-color:darken($red, 3%) !important;
      border-color:darken($red, 3%) !important;
      color:darken($red, 20%) !important;
    }
  }@else if($color_scheme == 'grey-to-red'){
    @if($actions_only == 'false'){
      background-color:$grey_light !important;
      border-color:$grey !important;
      color:lighten($text_color, 25%) !important;
    }
    &:hover{
      background-color: lighten($red, 3%) !important;
      border-color: lighten($red, 3%) !important;
      color:white !important;
    }
    &:active,
    &:focus{
      background-color:darken($red, 3%) !important;
      border-color:darken($red, 3%) !important;
      color:darken($red, 20%) !important;
    }
  }@else if( $color_scheme == 'green'){
    @if($actions_only == 'false'){
      background-color:$green !important;
      border-color:$green !important;
      color:white !important;
    }
    &:hover{
      background-color: lighten($green, 3%) !important;
      border-color: lighten($green, 3%) !important;
      color:white !important;
    }
    &:active,
    &:focus{
      background-color: darken($green, 3%) !important;
      border-color: darken($green, 3%) !important;
      color:darken($green, 20%) !important;
    }
  }@else if( $color_scheme == 'grey'){
    @if($actions_only == 'false'){
      background-color: darken($grey_light, 3%) !important;
      border-color: darken($grey_light, 3%) !important;
      color:darken($grey, 20%) !important;
    }
    &:hover{
      background-color: white !important;
      border-color: white !important;
      color:lighten($text_color, 10%) !important;
    }
    &:active,
    &:focus{
      background-color: darken($grey_light, 3%) !important;
      border-color: darken($grey_light, 3%) !important;
      color:darken($grey, 20%) !important;
    }
  }@else if( $color_scheme == 'ghost'){
    @if($actions_only == 'false'){
      background-color:transparent !important;
      border-color:rgba(0,0,0,0.1) !important;
      color:$text_color !important;
    }
    &:hover{
      background-color: transparent !important;
      border-color:rgba(0,0,0,0.2) !important;
      color:$highlight !important;
    }
    &:active,
    &:focus{
      background-color:transparent !important;
      border-color:rgba(0,0,0,0.3) !important;
      color:$text_color !important;
    }
  }@else if( $color_scheme == 'ghost-light'){
    @if($actions_only == 'false'){
      background-color:transparent !important;
      border-color:rgba(255,255,255,0.1) !important;
      color:$highlight_text_color !important;
    }
    &:hover{
      background-color: transparent !important;
      border-color:rgba(255,255,255,0.2) !important;
      color:$highlight_text_color !important;
    }
    &:active,
    &:focus{
      background-color:transparent !important;
      border-color:rgba(255,255,255,0.3) !important;
      color:$highlight_text_color !important;
    }
  }@else if( $color_scheme == 'highlight'){
    @if($actions_only == 'false'){
      // Active (default button active state)
      border-color:$highlight_sec !important;
      background-color:$highlight_sec !important;
      color: $highlight_text_color !important;
    }
    &:hover{
      border-color:lighten($highlight_sec, 5%) !important;
      background-color:lighten($highlight_sec, 5%) !important;
      color: $highlight_text_color !important;
    }
    &:active,
    &:focus{
      border-color:darken($highlight_sec, 5%) !important;
      background-color:darken($highlight_sec, 5%) !important;
      color: $highlight_text_color !important;
    }
  }@else if( $color_scheme == 'active'){
    @if($actions_only == 'false'){
      // Active (default button active state)
      border-color:darken($grey_light, 5%) !important;
      background-color:darken($grey_light, 8%) !important;
      color: $text_color !important;
    }
    &:hover{
      background-color: $grey_light !important; 
      border-color: $grey_light !important;
      color: $grey_dark !important;
    }
    &:active,
    &:focus{
      border-color:darken($grey_light, 5%) !important;
      background-color:darken($grey_light, 8%) !important;
      color: $text_color !important;
    }
  }@else{
    @if($actions_only == 'false'){
      border-color:$highlight !important;
      background-color:$highlight !important;
      color: $highlight_text_color !important;
    }
    &:hover{
      border-color:lighten($highlight, 5%) !important;
      background-color:lighten($highlight, 5%) !important;
      color: $highlight_text_color !important;
    }
    &:active,
    &:focus{
      border-color:darken($highlight, 5%) !important;
      background-color:darken($highlight, 5%) !important;
      color: $highlight_text_color !important;
    }
  }
}

/* Image Buttons (completely seperate button style) */
@mixin btn-image {
  text-indent:-9000px;
  display:inline-block;
  background-repeat:no-repeat;
  background-image:url(images/btn_sprite.png);
  background-color:transparent;
  border:0 none;
  outline:0 none;
  height:40px;
  width:154px; // fallback
}

// Undo Buttons
@mixin undo-button {
  font-size:$font_size;
  color:$text_color;
  background:none;
  border:0 none;
  padding:0;
  display:inline;
  &:hover{
    text-decoration:underline;
  }
}

// ***********************************************
// Icons
// ***********************************************

$icon_list: (
  search: "t", // Search
  zoom-in: "z", // Zoom
  zoom-out: "Z", // Zoom-Out
  check:"h", // Check
  circle-check:"H", // Circled Check
  remove:"x", // Remove (x)
  circle-remove:"X", // Circled Remove (x)
  plus:"+", // Plus
  circle-plus:"*", // Circled Plus
  minus:"-", // Minus
  circle-minus:"_", // Circled Minus
  exclamation:"1", // Exclamation Mark (Ausrufezeichen)
  circle-exclamation:"!", // Circled Exclamation Mark (Ausrufezeichen)
  info:"i", // info
  circle-info:"I", // Circled info
  menu:"m", // Menu
  process:"M", // Cogs (Zahnraeder)
  print:"f", // Printer
  phone:"p", // Phone
  mail:"b", // Letter
  form:"K", // Pen

  arrow-top:"w", // Arrow Top
  arrow-right:"d", // Arrow Right
  arrow-bottom:"s", // Arrow Bottom
  arrow-left:"a", // Arrow Left

  arrow-thin-top:"8", // Arrow thin Top
  arrow-thin-right:"6", // Arrow thin Right
  arrow-thin-bottom:"2", // Arrow thin Bottom
  arrow-thin-left:"4", // Arrow thin Left

  circle-arrow-top:"W", // Circled Arrow Top
  circle-arrow-right:"D", // Circled Arrow Right
  circle-arrow-bottom:"S", // Circled Arrow Bottom
  circle-arrow-left:"A", // Circled Arrow Left

  double-arrow-top:"Y", // Double Arrow Top
  double-arrow-down:"y", // Double Arrow Down
  locked:"l", // Lock (closed)
  unlocked:"u", // Lock (open)
  cart:"c", // Lock (open)
  home:"P", // Home
  share:"V", // Teilen
  file:"F", // File
  office:"o", // Office / Business
  user:"T", // User
  users:"U", // User Group
  map:"v", // Map Pointer
  xing:"7" // Xing
);

@mixin ico($icon_key: null, $icon_color: null, $icon_size: null){
  // Default inline icon
  &::before{
    font-family:$iconfont;
    margin-right:4px;
    font-weight:normal !important;
    font-style:normal !important;
    @if($icon_key != null){
      content: map-get($icon_list, $icon_key);
    }@else{
      @warn "Icon not found!";
    }
    @if($icon_color != null){
      color:$icon_color;
    }
    @if($icon_size != null){
      font-size:$icon_size;
    }
  }
}
@mixin ico-replacement($icon_key: null, $icon_color: null, $icon_size: null){
  // Replaces the elements text with an icon
  text-indent:-9000px;
  text-align:left;
  display:inline-block;
  width:16px;
  margin-right:4px;
  &::before{
    text-indent:0;
    font-family:$iconfont;
    float:right;
    @if($icon_key != null){
      content: map-get($icon_list, $icon_key);
    }@else{
      @warn "Icon not found!";
    }
    @if($icon_color != null){
      color:$icon_color;
    }
    @if($icon_size != null){
      font-size:$icon_size;
    }
  }
  @include clearfix-after;
}

@mixin get-ico($icon_key: null){
  // Gets only the icon letter (for custom implementation)
  @if($icon_key != null){
    &::before{
      content: map-get($icon_list, $icon_key);
    }
  }@else{
    @warn "Icon not found!";
  }
}

@mixin get-ico-only($icon_key: null){
  // Gets only the icon letter (for custom implementation)
  @if($icon_key != null){
    content: map-get($icon_list, $icon_key);
  }@else{
    @warn "Icon not found!";
  }
}

/* =============================================================================
 *   Navigation
 * ========================================================================== */

/* Tiny Menu */
@mixin tiny-menu($menu_direction: null){
  li{
    @if($menu_direction == "horizontal"){
      float:none;
      display:inline-block;
      margin-right:5px;
      &:last-child{
        margin-right:0;
      }
    }
    a{
      padding:2px 0;
    }
  }
}

/* =============================================================================
 *   Box Styles
 * ========================================================================== */

@mixin box-style($color_scheme: default){
  border-width:1px;
  border-style:solid;
  padding:$grid_vertical $grid;
  // Color
  @include box-style-mod($color_scheme);
  @include clearfix;
}

// Box Style Modifier
@mixin box-style-mod($color_scheme){
  // Adds only the new colors, not the entire box code
  @if($color_scheme == info){
    position:relative;
    font-size:$font_small;
    padding-left:60px;
    line-height:1.1em;
    border-color:darken($info_color, 8%);
    text-shadow:1px 1px 1px darken($info_color, 8%);
    background-color:$info_color;
    color:white;
    @include get-ico('info');
    &::before{
      text-shadow:none;
      font-family:$iconfont;
      color:darken($info_color, 8%);
      background-color:white;
      height:30px;
      width:30px;
      border-radius:50%;
      background-color:white;
      text-align:center;
      line-height:30px;
      vertical-align:middle;
      position:absolute;
      left:15px;
      top:7px;
      font-size:22px;
    }
  }@else{
    // Default
    border-color:$grey_light;
    border-radius:$border_radius;
    background-color:white;
  }
}

// ***********************************************
// Misc
// ***********************************************

/* Flyout */
@mixin flyout($padding: $mobile_grid, $direction: right){
  background:white;
  color:$text_color;
  padding:$padding;
  box-shadow:$shadow;
  min-width:235px;
  z-index:999;
  
  position:absolute;
  top:100%;
  @if($direction == left){
    right:0;
  }@else{
    left:0;
  }
}

/* Overlay */
@mixin overlay{
  display:none;
  position:absolute;
  top:0;
  left:0;
  z-index:9998;
  height:100%;
  width:100%; /* overriden by jquery */
  background:rgba(255,255,255,0.45);
}

/* Cutline */
@mixin cutline($direction: bottom){
  @if($direction == top){
    border-top:1px solid $grey_light;
    padding-top:0.85em;
    margin-top:0.85em;
  }@else if($direction == left){
    border-right:1px solid $grey_light;
    padding-right:0.85em;
    margin-right:0.85em;
  }@else if($direction == right){
    border-left:1px solid $grey_light;
    padding-left:0.85em;
    margin-left:0.85em;
  }@else{
    border-bottom:1px solid $grey_light;
    padding-bottom:0.85em;
    margin-bottom:0.85em;
  }
}

/* Group Titles (Views, Forms, ...) */
@mixin grouping-title{
  background:white;
  border-bottom:1px solid $grey_light;
  color:$highlight;
  padding:5px 10px;
  margin-top:0;
  a{
    color:$highlight;
  }
}