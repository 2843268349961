
// _base.scss imports _custom.scss and other partials.
@import "base";

@media only screen and (max-width: 480px) {
  /* 
   * Float Region blocks example:
   * In smaller screen sizes we can remove the float and widths so all blocks
   * stack instead of displaying horizonally. The selector used here is an
   * "attribute selector" which will match on any float block class. Use your
   * inspector or Firebug to get the classes from the page output if you need
   * more granular control over block alignment and stacking.
   * 
   * "Float Region blocks" is an extension for floating blocks in regions, see
   * your themes appearance settings, under the Extensions tab.
   */
  .region[class*="float-blocks"] .block {
    float: none;
    width: 100%;
  }
}

/* Small Devices */
@media only screen and (max-width: $tablet_portrait_end) {
  .hide-on-small-devices{
    display:none;
  }
}

/*
 * Tablet Portrait and up
 */
@media only screen and (min-width: $smartphone_end) {
	/* -- Header Image (Slideshow) */
  .block-views-header-slideshow-block{ display:block; clear:both; }
}
/*
 * Tablet sizes and up
 */
@media only screen and (min-width: $tablet_portrait_end) {
  /* -- Header */
  .branding{ float:left; width:35%; margin:0; }
  .region-header{ display:block; float:left; width:65%; }
  .region-header .block-search{ float:right; }
}

/*
 * Tablet Landscape and up (Sidebar right)
 */
@media only screen and (min-width: $tablet_landscape_start) {

}

/*
 * Desktops/laptops and up
 */
@media only screen and (min-width: $desktop_min_width) {

}